#imprint {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    /* align-items: ; */
}
#imprintWrapper {
    /* position: relative; */
    /* width: 100vh; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* display: flex; */
    max-width: 90%;
    margin: 10px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: justify;
    font-size: 11px;
    padding-top: 30px;
    font-size-adjust: inherit;
    border-left: solid black 4px;
    border-right: solid black 4px;
    /* padding: 100px; */
    /* height: 100vh; */
}

#back {
    position: absolute;
    top: 30px;
    /* padding: 10px; */

    text-decoration: overline;
    text-underline-offset: 5px;
    color: black;
}

.imprintText {
    position: relative;
    /* width: 60%; */
}
