.merchSlider {
    position: relative;
    /* display: flex; */
    /* left: 65%; */
    width: 100%;
    max-width: 500px;
    /* transform: translateX(-50%); */
    flex-direction: column;
    resize: both;
    transition: ease;
    color: black;
    object-fit: cover;
    /* border: solid red 1px; */
    margin: 50px;
}

.sliderContent {
    position: relative;
    display: flex !important;
    align-items: center;
    max-width: 600px;
    justify-content: center;
    overflow: hidden;
    object-fit: cover;
    flex-direction: column;
}
.merchInfo {
    display: flex;
    position: relative;
    width: 100%;
    transition: 0.5s ease;
    opacity: 1;
    color: white;
    text-align: center;
    border-radius: 6px;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: solid red 1px; */
    text-align: center;
    /* line-height: 3px; */
}
.merchSliderImage {
    width: 100%;
    max-height: 400px;
    max-width: 400px;
    padding: 10px;
    object-fit: cover;
    resize: both;
    border-radius: 20px;
}
.smallSlider {
    margin-top: 45px;
}
.smallSliderContent {
}
.smallSliderContent img {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 20px;
    padding: 10px;
}

@media (max-width: 850px) {
    .smallSlider {
        display: none;
    }
}

.slick-slider {
    /* margin: 0 -15px; */
}
.slick-slide {
    text-align: center;
}

.slick-slide > * {
    display: flex;
}
.slick-arrow {
    position: relative;
    right: 50px;
    /* border: 2px red solid; */
}
.merchSliderDescription {
    display: flex;
    text-align: center;

    padding-left: 10px;
    color: black;
    font-style: normal;
    margin-right: 7%;
}

.merchButton {
    cursor: pointer;
    font-size: 18px;
    line-height: 1;
    border-radius: 50px;
    transition-property: background-color, border-color, color, box-shadow,
        filter;
    display: flex;
    transition-duration: 0.3s;
    border: 1px solid transparent;
    letter-spacing: 2px;
    /* padding-top: 20px; */
    padding: 18px 38px 23px;
    color: black;
    box-shadow: inset 0 0 0 2px #616467;
    background-color: transparent;
    height: 48px;
    text-decoration: none;
    /* background-color: rgba(255, 255, 255, 0.182); */
    /* padding-left: 10px; */
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}
.merchButton:hover {
    color: white;
    background-color: #152565;
    border: 1px solid white;
}

/* ================================= */
.slick-prev {
    left: 10px;
    /* transform: rotate(180deg) !important; */
}

.slick-prev,
.slick-next {
    position: absolute;
    top: 50%;
    z-index: 2;
    /* display: block; */
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    color: red;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    font-size: 40px;
    opacity: 0.75;
    color: black;
    /* 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}
/* .slick-list {
    height: auto;
} */
/* .slick-prev:before {
    position: relative;
    left: -50px;
}
.slick-next:before {
    position: relative;

    right: -70px;
} */
