* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* -moz-transform: scale(0.8, 0.8); Moz-browsers */
    /* zoom: 0.8; Other non-webkit browsers */
    /* zoom: 80%; Webkit browsers */
}

@font-face {
    font-family: "gms_inkjet_intlregular";
    src: url("/src/fonts/GMSInkjetIntl-Regular.woff2") format("woff2"),
        url("/src/fonts/GMSInkjetIntl-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

body {
    /* -moz-transform: scale(0.8, 0.8); */
    /* zoom: 0.8; */
    /* zoom: 80%; */
}
.App {
    /* text-align: center; */
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loadingAnimation {
    display: flex;
    align-items: center;
    flex-direction: column;
}
