#artists {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

#about {
    background-color: "0x9a9eb6";
}

.artistGrid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 70%;
}
.artistWrapper {
    margin: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 300px;
    max-height: 300px;
    object-fit: cover;
    resize: both;
}
.artistWrapper img {
    max-width: 250px;
    max-height: 250px;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
}

.artistInfo {
    position: absolute;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 250px;
    min-height: 250px;
    border-radius: 50%;
    /* box-shadow: 2px -1px 8px 2px white; */
    color: #152565;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.56);
    flex-direction: column;
}

.artistInfo:hover {
    opacity: 1;
    transition: 0.5s ease;
}
