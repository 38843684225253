.heading {
    /* border: red solid 1px; */
    /* font-size: 50px; */
}
.sectionHeadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.headingAnimation {
    position: relative;
    /* left: 50%; */

    /* position: relative; */

    /* border: red solid 1px; */
    /* height: 30vh;
}

.sectionHeadingWrapper {
    /* position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: red solid 1px; */
    /* height: 200px; */
}

#animator {
    z-index: -1;
}
