.eventSlider {
    position: relative;
    /* display: flex; */
    left: 50%;
    /* max-width: auto; */
    max-width: 80%;
    transform: translateX(-50%);
    /* flex-direction: column; */
    resize: both;
    transition: ease;
    color: black;
    object-fit: contain;
    align-items: center;
    margin-top: -50px;
}

.slick-slider {
}
.slick-slide {
    text-align: center;
}
.slick-slide > * {
    display: initial !important;
    max-height: 60rem;
    max-width: 100%;
}

.slick-arrow {
    position: relative;
    right: -0px;
    /* border: 2px red solid; */
}

.slick-prev {
    left: -50px;
    /* transform: rotate(180deg) !important; */
}

.slick-prev,
.slick-next {
    position: absolute;
    top: 50%;
    z-index: 2;
    /* display: block; */
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    color: black;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    /* font-family: "slick"; */
    font-size: 40px;
    /* line-height: 1; */

    opacity: 0.75;
    color: black;

    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}
/* Your existing styles... */

/* Position the slider arrows on the left and right sides */
.slick-prev {
    left: 20px; /* Adjust the value as needed to position the left arrow */
}

.slick-next {
    right: 20px; /* Adjust the value as needed to position the right arrow */
}
@media screen and (max-width: 768px) {
    .slick-prev,
    .slick-next {
        display: none;
    }
}
