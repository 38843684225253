#releases {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    align-items: center;
    /* border: solid 1px; */
    height: auto;
}

.releasesSlider {
    position: relative;
    /* display: flex; */
    /* left: 65%; */
    width: 100vw;
    height: 50vh;
    flex-direction: row;
    resize: both;
    transition: ease;
    object-fit: cover;
    /* border: solid red 1px; */
    padding: 0px 50px;
}

.albumCover {
    width: 80%;
    border-radius: 5px;
}

.albumDescription {
    /* border: green solid 1px; */
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 100%;
    width: 90%;
    justify-content: center;
    gap: 15px;
    padding: 20px;
    /* align-items: center; */
}

.releasesContent {
    display: grid !important;
    grid-template-columns: 2fr 3fr 2fr;
    /* flex-direction: row; */
    height: 50vh;

    /* border: blue solid 1px; */
    justify-items: center;
    align-items: center;
}
