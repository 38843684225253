#podcast {
    /* height: auto; */
    /* border: red solid 1px; */
}
.soundCloudPlayer {
    /* box-shadow: 9px -8px 15px -12px #ff8800; */
    max-width: 600px;
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;

    border-radius: 6px;
    padding: 10px;
    width: 30vw;
    height: 400px;
}

.podcastSectionWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.podcastDescription {
    align-items: center;
    border-radius: 6px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    font-weight: 300;
    justify-content: space-between;
    margin: 10px;
    max-width: 500px;
    padding: 10px;
    line-height: 150%;
    border-bottom: solid #ff8800 2px;
    border-top: solid #ff8800 2px;
}

#reachOut {
    color: #ff8800;
}
