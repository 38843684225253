* {
    box-sizing: border-box;
    font-family: "Archivo Black", sans-serif;
    /* font-family: "GMS Injekt Intl"; */
    /* font-family: "Righteous", cursive; */
    font-family: "gms_inkjet_intlregular";

    font-display: swap;
    margin: 0;
    /* text-transform: uppercase; */
}

.pageSection {
    height: auto;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.sectionWrapper {
    display: flex;
    z-index: 90;
    position: relative;
    flex-direction: column;
}
.trennerContainer {
    height: 115px;
    display: flex;
    align-items: center;
}

.trennerLinie {
    z-index: 2;
    position: relative;
    left: 50%;
    height: 3px;
    width: 60vw;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.59);
    transform: translateX(-50%);
}
.sectionHeading {
    font-size: 7vw;
    text-align: center;
    color: #152565;
    margin-top: 30px;
    letter-spacing: 15px;
    margin-bottom: 10px;
    /* font-family: "Righteous", cursive; */
    /* font-family: "Archivo Black", sans-serif; */
}

@media (max-width: 850px) {
    .sectionHeading {
        font-size: 7vw;
        text-align: center;
        color: #152565;
        margin-top: 35px;
        letter-spacing: 10px;
        /* margin-bottom: 10px; */
        /* font-family: "Archivo Black", sans-serif; */
        font-family: "gms_inkjet_intlregular";
    }
}

/* @font-face {
    font-family: "gms_inkjet_intlregular";
    src: url("/Downloads/SOUNDSOUND_Paket_März_Korr./_Schrift/GMS Inkjet Intl/Web")
            format("woff2"),
        url("/Downloads/SOUNDSOUND_Paket_März_Korr./_Schrift/GMS Inkjet Intl/Web")
            format("woff");
    font-weight: normal;
    font-style: normal;
} */
