#merch {
    display: flex;
    /* position: relative; */
    justify-content: space-around;
    align-items: center;
    /* background-color: #dddde3; */
    /* border: 1px red solid; */
    /* flex-direction: row; */
}

.merchArticleWrapper {
    position: relative;
    display: flex !important;
    height: auto;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    object-fit: scale-down;
    /* background-color: #dddde3; */
}

.merchImage {
    /* z-index: 2; */
    max-width: 400px;
    max-height: 400px;
    /* width: 25rem;
    height: 25rem; */
    object-fit: cover;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
/* .merchImage:hover {
    height: 400px;
    width: 400px;
    margin: 20px;
    transition: 0.5s ease;

    object-fit: cover;
} */

.merchArticleWrapper:hover .merchInfo {
    opacity: 1;
}

.merchGrid {
    /* z-index: 3; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.articlePrice {
    position: relative;
}

.shopWrapper {
    /* position: absolute; */
    /* display: flex;
    align-items: center;
    justify-items: center; */
}

.shopWrapper h1 {
    cursor: pointer;
}

.shop {
    /* position: absolute; */
    /* top: 50%;
    left: 20%; */
}

.shop button {
    width: 18rem;
    font-size: 3rem;
    cursor: pointer;
    background: none;
    border: none;
    cursor: pointer;
    /* outline: inherit; */
    border: 4px solid black;
    border-radius: 50px;
    color: black;
    padding-bottom: 6px;
}

#shopButton:hover {
    border: 6px solid black;
}

.sliderContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
