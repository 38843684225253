.navbar {
    display: flex;
    align-items: center;
    width: auto;
    min-width: 850px;
    top: -10px;
    position: sticky;
    background-color: rgba(255, 255, 255);
    top: 0px;
    padding: 0 3.2rem;
    box-shadow: 0px 1px 6px -1px black;
    z-index: 1000;
    height: 70px;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 50px;
}

.socialsNavbar {
    display: flex;
    align-items: center;
    padding-right: 20px;
}
.socialIcon {
    width: 30px;
    /* margin: 5px; */
    margin-left: 15px;
    cursor: pointer;
}

.socialIcon:hover {
    border-bottom: 2px solid black;
}

#igIcon:hover {
    border-bottom: 3px solid rgb(193, 53, 132);
}
#scIcon:hover {
    border-bottom: 3px solid #ff8800;
}
#spotifyIcon:hover {
    border-bottom: 3px solid #1db954;
}

#youtubeIcon:hover {
    border-bottom: 3px solid #ff0200;
}
.navbar-item {
    display: flex;
    border-bottom: 1px solid black;
    width: 90px;
    /* border-radius: 6px; */
    padding: 10px;
    margin: 10px;
    align-items: center;
    justify-content: center;
}

.navbar-item > * {
    font-size: 12px;
}
.navbar-item:hover {
    cursor: pointer;
    border-bottom: 3px solid black;
}

.toggleMenu {
    z-index: 10;
    padding: 10px;
    top: 10%;

    position: absolute;
    right: 10px;
    top: 10px;
}

#navLogo {
    position: relative;
    left: 20;
    width: 80px;
    cursor: pointer;
}
.navLogoContainer {
    /* padding-left: 30px; */
    /* height: 100%; */
}

@media (prefers-reduced-motion: no-preference) {
    #navLogo {
        /* animation: App-logo-spin infinite 10s linear; */
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 850px) {
    .navbar {
        text-align: center;
        display: none;
    }
}

/* MOWILE NAVBAR  */
.mobileNavLogoContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 1px 6px -1px black;
    height: 75px;
}

.mobileNavbar {
    display: flex;
    flex-direction: row;
    /* position: sticky; */
    /* position: absolute; */
    top: 0px;
    z-index: 10;
    position: sticky;
    background-color: rgba(255, 255, 255);
    display: none;
    width: 100vw;
    background-color: white;
    top: 0px;
    box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.893);
    z-index: 1000;
}

@media (max-width: 850px) {
    .mobileNavbar {
        display: inline-block;
        text-align: center;
    }
}

.mobileMenu {
    display: flex;
    /* height: 50vh; */
    width: 100%;
    position: absolute;
    z-index: 3;
    /* top: 0; */
    left: 0;
    background-color: white;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 6px -1px black;
}

.mobileMenu-item {
    padding: 8px 8px 8px 32px;
    /* text-decoration: none; */
    font-size: 25px;
    color: black;
    display: flex;
    transition: 0.3s;
    border-bottom: 1px solid black;
    width: 90px;
    /* border-radius: 6px; */
    z-index: 2;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    justify-content: center;
}
.mobileMenu-item:hover {
    color: #f1f1f1;
}

/* HAMBURGER MENNU ========= */
.hamburgerMenu {
    width: 35px;
    height: 30px;
    margin: 8px 8px;
    position: relative;
    cursor: pointer;
    display: inline-block;
}
.hamburgerMenu span {
    background-color: black;
    position: absolute;
    border-radius: 2px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    width: 100%;
    height: 4px;
    transition-duration: 500ms;
}
.hamburger {
}

.hamburgerMenu span:nth-child(1) {
    top: 0px;
    left: 0px;
}
.hamburgerMenu span:nth-child(2) {
    top: 13px;
    left: 0px;
    opacity: 1;
}
.hamburgerMenu span:nth-child(3) {
    bottom: 0px;
    left: 0px;
}

.hamburgerMenu:not(.open):hover span:nth-child(1) {
    left: 3px;
    top: 12px;
    width: 30px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    transform: rotate(90deg);
    transition-delay: 150ms;
}

.hamburgerMenu:not(.open):hover span:nth-child(2) {
    left: 2px;
    top: 20px;
    width: 20px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    transform: rotate(45deg);
    transition-delay: 50ms;
}
.hamburgerMenu:not(.open):hover span:nth-child(3) {
    left: 14px;
    top: 20px;
    width: 20px;
    transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
    transform: rotate(-45deg);
    transition-delay: 100ms;
}

.hamburgerMenu.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 13px;
}
.hamburgerMenu.open span:nth-child(2) {
    opacity: 0;
}
.hamburgerMenu.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 13px;
}

/* .mobileMenuDropdown-enter {
    position: absolute;
    transform: translateY(-110%);
}
.mobileMenuDropdown-enter-active {
    transform: translateX(0%);
    transition: all 1 ease;
}
.mobileMenuDropdown-exit {
    position: absolute;
}
.mobileMenuDropdown-enter-active {
    transform: translateY(-110%);
    transition: all 1 ease;

} */
.rotate {
    transition: transform 0.5s ease;
    z-index: 100;
}

.rotate-img {
    transform: rotate(180deg);
    transition: transform 0.5s ease-out;
}

#demo {
    color: black;
    text-decoration: none;
}
