.footerWrapper {
    display: flex;
    height: auto;
    background-color: white;
    position: relative;
    bottom: 0px;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 50px;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-top: 2px solid black; */
    box-shadow: 0px 1px 6px -1px black;
}

.footerLeft {
    border: solid 1px;
}
.footerRight {
    border: solid 1px;
}

.socialLinks {
}
#impressum {
    color: black;
}

#gitHub {
    color: black;
}

.footerInfo {
    position: relative;
    /* left: 10px; */
    font-size: 12px;
    /* float: right; */
}
