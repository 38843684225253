#events {
    display: flex;
    position: relative;
    justify-content: center;
}

.eventWrapper {
    position: relative;
    display: flex !important;
    height: 600px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    object-fit: scale-down;
}

#eventHeading {
    position: relative;
    top: 30px;
}

#eventFlyer {
    position: relative;
    max-width: 500px;
    object-fit: cover;
    resize: both;
    max-height: 400px;
    /* max-width: 414px; */
    border-radius: 6px;
    box-shadow: 4px 2px 16px 0px rgba(0, 0, 0, 0.76);
}
#eventFlyer:hover {
    color: black;
    z-index: 2;
}

.hoverDiv {
    position: relative;
    display: flex !important;
    align-items: center;
    border-radius: 6px;
    flex-direction: column;
    justify-content: center;
}

.eventFlyer:hover .hoverDiv {
    transform: scale(1);
}
.eventInfo {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: 0.5s ease;
    opacity: 0;
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 6px;
}
.eventInfo:hover {
    opacity: 1;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

#ticketLink {
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding-top: 15px;
}

.ticketButton {
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    border-radius: 500px;
    transition-property: background-color, border-color, color, box-shadow,
        filter;
    transition-duration: 0.3s;
    border: 1px solid transparent;
    letter-spacing: 2px;
    min-width: 160px;
    text-transform: uppercase;
    margin-top: 26px;
    padding-top: 20px;
    padding: 16px 28px 23px;
    color: white;
    box-shadow: inset 0 0 0 2px #616467;

    background-color: transparent;
    height: 48px;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.182);
}

.ticketButton:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.382);
}
