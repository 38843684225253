#hero {
    position: relative;
    z-index: -3;
    align-content: center;
    flex-direction: column;
}

.heroImage {
    object-fit: cover;
    width: 100vw;
    max-height: 80vh;
}

.heroContainer {
    max-width: 100%;
    z-index: -3;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

#logoHero {
    position: absolute;
    /* width: 100%; */
    z-index: 100;
    /* left: 50%; */
    width: clamp(4rem, 1rem + 60vw, 25rem);
}
#heroImageWrapper {
    display: flex;
    background-color: #dddde3;
    /* z-index: -3; */
    /* max-height: 1182px; */
    /* min-height: 53vh; */
    min-height: 100%;
    align-items: center;
    justify-content: center;
}
.heroImageWrapper:nth-child(3) {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}
.heroImageWrapper:nth-child(1) {
    max-height: 1200px;
}

#animator {
    /* z-index: -1; */
}

.rotate {
    transition: transform 0.5s ease;
    z-index: 100;
}

.rotate-img {
    transform: rotate(180deg);
    transition: transform 0.5s ease-out;
}
